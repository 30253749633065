import React, { useState } from 'react';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
  LoginContainer,
  LoginBox,
  Title,
  InputContainer,
  Input,
  Icon,
  Button,
  Link,
  ErrorText,
} from './LoginStyles'; // Importando o CSS separado

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/profile');
    } catch (error) {
      setError('Falha no login. Verifique suas credenciais.');
    }
  };

  return (
    <LoginContainer>
      <LoginBox>
        <Title>Login</Title>
        {error && <ErrorText>{error}</ErrorText>}
        <form onSubmit={handleLogin}>
          <InputContainer>
            <Icon>
              <FaUserAlt />
            </Icon>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <Icon>
              <FaLock />
            </Icon>
            <Input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </InputContainer>
          <Button type="submit">Entrar</Button>
        </form>
        <Link onClick={() => navigate('/forgot-password')}>Esqueceu a senha?</Link>
        <Link onClick={() => navigate('/register')}>Criar uma conta</Link>
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
