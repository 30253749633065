import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import './ColorSelection.css';
import {colors} from '../../data/options';

const ColorSelection = ({ selectedColor, onColorSelected }) => {
  const handleColorClick = (colorName) => {
    if (selectedColor === colorName) {
      onColorSelected(null);
    } else {
      onColorSelected(colorName);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" className="color-selection-container">
      {Object.entries(colors).map(([colorName, colorValue]) => (
        <Grid item key={colorName}>
          <Tooltip title={colorName} arrow>
            <Box
              onClick={() => handleColorClick(colorName)}
              className={`color-circle ${selectedColor === colorName ? 'selected' : ''}`}
              sx={{
                backgroundColor: colorValue,
              }}
            />
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default ColorSelection;