import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

/**
 * Faz o upload das imagens do carro para o Firebase Storage e retorna os URLs de download.
 * 
 * @param {string} carId - O ID do carro que será usado como parte do caminho de armazenamento das imagens.
 * @param {Array} carImages - Um array de arquivos de imagem para fazer upload.
 * @param {Object} storage - A instância do Firebase Storage.
 * 
 * @returns {Promise<Array<string>>} - Retorna um array de URLs das imagens armazenadas.
 */
export const uploadCarImages = async (carId, carImages, storage) => {
  try {
    const uploadPromises = carImages.map(async (image, index) => {
      const imageRef = ref(storage, `carImages/${carId}/${carId}_${index + 1}`);

      // Verifica o tipo do arquivo
      if (!(image instanceof File || image instanceof Blob)) {
        throw new Error(`Arquivo inválido: ${image.name || 'sem nome'}`);
      }

      // Faz o upload da imagem para o Firebase Storage
      const snapshot = await uploadBytes(imageRef, image);
      
      // Obtém o URL da imagem armazenada
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    });

    // Espera que todos os uploads sejam completados
    const imageUrls = await Promise.all(uploadPromises);
    return imageUrls;
    
  } catch (error) {
    console.error('Erro ao fazer upload das imagens:', error);
    throw new Error('Erro ao fazer upload das imagens.');
  }
};