import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from '../../firebaseConfig';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ImagePicker from '../../components/ImagePicker/ImagePicker';
import ColorSelection from '../../components/ColorSelection/ColorSelection';
import { Box, Button, Grid, MenuItem, TextField, Typography, Switch, FormControlLabel, Checkbox, FormControl, FormLabel, FormGroup } from '@mui/material';
import { brandOptions, fuelOptions, transmissionOptions, categoryOptions } from '../../data/options'; // Importe as opções de categorias
import './EditCar.css';

const EditCarScreen = () => {
  const { carId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]); // Adicione o estado para as categorias
  const [selectedBrand, setSelectedBrand] = useState('');
  const [model, setModel] = useState('');
  const [selectedMotor, setSelectedMotor] = useState('');
  const [selectedFuel, setSelectedFuel] = useState('');
  const [selectedTransmission, setSelectedTransmission] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [km, setKm] = useState('');
  const [year, setYear] = useState('');
  const [isArmored, setIsArmored] = useState(false);
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [carImages, setCarImages] = useState([]);
  const [carImageUrls, setCarImageUrls] = useState([]);

  useEffect(() => {
    loadCarDetails();
  }, []);

  const loadCarDetails = async () => {
    try {
      const carDoc = await getDoc(doc(firestore, 'cars', carId));
      if (carDoc.exists()) {
        const carData = carDoc.data();
        setSelectedBrand(carData.brand);
        setModel(carData.model);
        setSelectedMotor(carData.motor);
        setSelectedFuel(carData.fuel);
        setSelectedTransmission(carData.transmission);
        setSelectedColor(carData.color);
        setKm(carData.km);
        setYear(carData.year);
        setIsArmored(carData.armored);
        setPrice(carData.price);
        setDescription(carData.description);
        setCarImageUrls(carData.imageUrls);
        setSelectedCategories(carData.categories || []); // Carregue as categorias
      } else {
        toast.error('Carro não encontrado.');
        navigate('/catalog');
      }
    } catch (error) {
      toast.error('Erro ao carregar detalhes do carro.');
    }
  };

  const handleCategoryChange = (categoryValue) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryValue)
        ? prevSelected.filter((cat) => cat !== categoryValue)
        : [...prevSelected, categoryValue]
    );
  };
  

  const uploadCarImagesToStorage = async () => {
    const downloadUrls = [];
    for (const image of carImages) {
      const imageRef = ref(storage, `cars/${carId}/${image.name}`);
      await uploadBytes(imageRef, image);
      const downloadUrl = await getDownloadURL(imageRef);
      downloadUrls.push(downloadUrl);
    }
    return downloadUrls;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let updatedImageUrls = carImageUrls;
      if (carImages.length > 0) {
        const newImageUrls = await uploadCarImagesToStorage();
        updatedImageUrls = [...carImageUrls, ...newImageUrls];
      }

      await updateDoc(doc(firestore, 'cars', carId), {
        brand: selectedBrand,
        model,
        motor: selectedMotor,
        fuel: selectedFuel,
        transmission: selectedTransmission,
        color: selectedColor,
        km: parseInt(km, 10),
        year,
        armored: isArmored,
        price: parseFloat(price),
        description,
        imageUrls: updatedImageUrls,
        categories: selectedCategories,
      });

      toast.success('Carro atualizado com sucesso!');
      navigate('/catalog');
    } catch (error) {
      toast.error('Erro ao atualizar carro.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="edit-car-screen" sx={{ maxWidth: '800px', margin: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Editar Carro</Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          {/* Marca */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Marca"
              select
              fullWidth
              variant="outlined"
              value={selectedBrand}
              onChange={(e) => setSelectedBrand(e.target.value)}
              displayEmpty
            >
              <MenuItem value=""><em>Selecione a marca</em></MenuItem>
              {brandOptions.map((brand) => (
                <MenuItem key={brand} value={brand}>{brand}</MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Modelo */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Modelo"
              fullWidth
              variant="outlined"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              placeholder="Modelo"
            />
          </Grid>

          {/* Motor */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Motor"
              select
              fullWidth
              variant="outlined"
              value={selectedMotor}
              onChange={(e) => setSelectedMotor(e.target.value)}
              displayEmpty
            >
              <MenuItem value=""><em>Selecione o motor</em></MenuItem>
              <MenuItem value="1.0">1.0</MenuItem>
              <MenuItem value="1.6">1.6</MenuItem>
              <MenuItem value="2.0">2.0</MenuItem>
            </TextField>
          </Grid>

          {/* Combustível */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Combustível"
              select
              fullWidth
              variant="outlined"
              value={selectedFuel}
              onChange={(e) => setSelectedFuel(e.target.value)}
              displayEmpty
            >
              <MenuItem value=""><em>Selecione o combustível</em></MenuItem>
              {fuelOptions.map((fuel) => (
                <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Transmissão */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Transmissão"
              select
              fullWidth
              variant="outlined"
              value={selectedTransmission}
              onChange={(e) => setSelectedTransmission(e.target.value)}
              displayEmpty
            >
              <MenuItem value=""><em>Selecione a transmissão</em></MenuItem>
              {transmissionOptions.map((transmission) => (
                <MenuItem key={transmission} value={transmission}>{transmission}</MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Cor */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Cor
            </Typography>
            <ColorSelection selectedColor={selectedColor} onColorSelected={setSelectedColor} />
          </Grid>

          {/* Quilometragem */}
          <Grid item xs={12} sm={6}>
            <NumericFormat
              label="KM Rodados"
              fullWidth
              variant="outlined"
              customInput={TextField}
              thousandSeparator="."       // Configura o separador de milhar como ponto
              decimalSeparator=","
              suffix=' km'        // Configura o separador decimal como vírgula
              value={km}
              onValueChange={(values) => setKm(values.value)}
              placeholder="KM"
              allowNegative={false}       // Impede valores negativos
              isNumericString             // Mantém o valor como string numérica
            />
          </Grid>

          {/* Ano */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Ano"
              fullWidth
              variant="outlined"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              placeholder="Ano"
            />
          </Grid>

          {/* Preço */}
          <Grid item xs={12} sm={6}>
            <NumericFormat
              label="Preço"
              fullWidth
              variant="outlined"
              customInput={TextField}
              thousandSeparator="."       // Configura o separador de milhar como vírgula
              decimalSeparator=","        // Configura o separador decimal como ponto
              prefix="R$ "                // Adiciona o prefixo "R$"
              value={price}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={(values) => setPrice(values.value)}
              placeholder="Preço"
              allowNegative={false}       // Impede valores negativos
              isNumericString             // Mantém o valor como string numérica
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Categoria</FormLabel>
              <FormGroup row>
                {categoryOptions.map((category) => (
                  <FormControlLabel
                    key={category.value}
                    control={
                      <Checkbox
                        checked={selectedCategories.includes(category.value)}
                        onChange={() => handleCategoryChange(category.value)}
                        color="primary"
                      />
                    }
                    label={category.label}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          {/* Descrição */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Descrição
            </Typography>
            <ReactQuill value={description} onChange={setDescription} />
          </Grid>

          {/* Blindado */}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch checked={isArmored} onChange={() => setIsArmored(!isArmored)} />}
              label="Blindado"
            />
          </Grid>

          {/* Seletor de imagens */}
          <Grid item xs={12}>
            <ImagePicker carImages={carImages} carImageUrls={carImageUrls} setCarImages={setCarImages} setCarImageUrls={setCarImageUrls} isEditMode={true} />
          </Grid>

          {/* Botão de envio */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
              {isLoading ? 'Atualizando...' : 'Atualizar Carro'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditCarScreen;

// Estilos e opções
const containerStyle = {
  padding: '20px',
  backgroundColor: '#F4F6F8',
  borderRadius: '12px',
};

const titleStyle = {
  mb: 3,
  textAlign: 'center',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
  color: '#333',
};

const sectionTitleStyle = {
  mb: 2,
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
  color: '#333',
};