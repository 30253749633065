import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import {
  Typography, Container, CircularProgress, Grid, Card, CardMedia,
  Box, Button, Chip, Modal, TextField, IconButton, Tooltip
} from '@mui/material';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SpeedIcon from '@mui/icons-material/Speed';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const CarDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [date, setDate] = useState('');
  const [location, setLocation] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isVendor, setIsVendor] = useState(false);

  useEffect(() => {
    const fetchCar = async () => {
      try {
        const carDocRef = doc(firestore, 'cars', id);
        const carDoc = await getDoc(carDocRef);
        if (carDoc.exists()) {
          const carData = carDoc.data();
          setCar(carData);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching car:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUser = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        setCurrentUser(user);
        setIsVendor(userData?.isVendor || false);
      }
    };

    fetchCar();
    fetchUser();
  }, [id]);

  const handleOpenTestDriveModal = () => setOpenModal(true);
  const handleCloseTestDriveModal = () => setOpenModal(false);

  const handleScheduleTestDrive = () => {
    const address = `${location.street}, ${location.number}, ${location.city}, ${location.zipCode}, ${location.complement || ''}`;
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    const message = `Olá, gostaria de saber a disponibilidade para agendar um test drive para o modelo ${car.brand} ${car.model} ${car.year} no dia ${new Date(date).toLocaleString('pt-BR')} no endereço: ${googleMapsLink}`;
    window.open(`https://wa.me/5511986300771?text=${encodeURIComponent(message)}`, '_blank');
    handleCloseTestDriveModal();
  };

  const handleRedirectEdit = () => navigate(`/edit/${id}`);
  const handleToggleFavorite = () => setIsFavorite(!isFavorite);

  const handleNegotiate = () => {
    const message = `Olá, gostaria de negociar sobre o modelo ${car.brand} ${car.model} ${car.year}. Aqui está o ${window.location.href}`;
    window.open(`https://wa.me/5511986300771?text=${encodeURIComponent(message)}`, '_blank');
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    adaptiveHeight: true,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  if (!car) {
    return (
      <Container>
        <Typography variant="h5" color="error" align="center">Carro não encontrado</Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{car ? `${car.brand} ${car.model}` : 'Carro não encontrado - Road Cars Motors'}</title>
        <meta
          property="og:title"
          content={car ? `${car.brand} ${car.model}` : 'Carro não encontrado - Road Cars Motors'}
        />
        <meta
          property="og:description"
          content={car ? `Confira todos os detalhes do ${car.brand} ${car.model} ${car.year} agora mesmo!` : 'Detalhes do carro não disponíveis.'}
        />
        <meta
          property="og:image"
          content={car && car.imageUrls && car.imageUrls[0] ? car.imageUrls[0] : `${window.location.origin}/assets/default-car.jpg`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="fb:app_id" content="1291552575609078" />
      </Helmet>
      <div style={{ padding: '40px', margin: 'auto', maxWidth: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={4}>
          {/* Slider de Imagens */}
          <Grid item xs={12} md={8}>
            <Slider {...sliderSettings}>
              {car.imageUrls.map((url, index) => (
                <Box key={index}>
                  <Card sx={{ boxShadow: 5, borderRadius: '15px' }}>
                    <CardMedia
                      component="img"
                      alt={`Car Image ${index + 1}`}
                      image={url}
                      sx={{ borderRadius: '15px', objectFit: 'cover', width: '100%', height: 'fitContent' }}
                    />
                  </Card>
                </Box>
              ))}
            </Slider>
          </Grid>

          {/* Descrição e Preço */}
          <Grid item xs={12} md={4}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                {currentUser && isVendor && (
                  <Tooltip title="Editar carro">
                    <IconButton onClick={handleRedirectEdit} color="primary">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {currentUser && (
                  <Tooltip title="Favoritar carro">
                    <IconButton onClick={handleToggleFavorite} color={isFavorite ? 'secondary' : 'default'}>
                      <FavoriteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Grid>

            <Card variant="outlined" sx={{ padding: 3, borderRadius: '10px', mb: 3 }}>
              <Typography variant="h4" fontWeight="bold" gutterBottom>{car.brand} {car.model} {car.year}</Typography>
              <Chip label={`R$ ${car.price.toLocaleString('pt-BR')}`} color="success" sx={{ fontSize: '1.2rem', mt: 2, mb: 4 }} />
              <Typography variant="h6" sx={{ color: '#34495e', fontWeight: 'bold' }}>Descrição</Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
                dangerouslySetInnerHTML={{ __html: car.description }}
              />
            </Card>
          </Grid>

          {/* Especificações */}
          <Grid item xs={12} md={6}>
            <Card variant="outlined" sx={{ padding: 3, borderRadius: '10px' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Especificações</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <SpeedIcon sx={{ mr: 1 }} />
                <Typography><strong>Quilometragem:</strong> {car.km.toLocaleString('pt-BR')} km</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <ColorLensIcon sx={{ mr: 1 }} />
                <Typography><strong>Cor:</strong> {car.color}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <LocalGasStationIcon sx={{ mr: 1 }} />
                <Typography><strong>Combustível:</strong> {car.fuel}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography><strong>Motor:</strong> {car.motor}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography><strong>Blindado:</strong> {car.armored ? 'Sim' : 'Não'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography><strong>Transmissão:</strong> {car.transmission}</Typography>
              </Box>
            </Card>
          </Grid>

          {/* Ações */}
          <Grid item xs={12} md={5}>
            <Card variant="outlined" sx={{ padding: 3, borderRadius: '10px' }}>
              <Typography variant="h6">Ações</Typography>
              <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Button variant="contained" color="primary" fullWidth onClick={handleOpenTestDriveModal}>
                  Agendar Test Drive
                </Button>
                <Button variant="outlined" color="secondary" fullWidth onClick={handleNegotiate}>
                  Negociar
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Modal open={openModal} onClose={handleCloseTestDriveModal}>
          <Box sx={{ padding: 4, borderRadius: 2, backgroundColor: 'white', width: '400px', mx: 'auto', mt: '10%' }}>
            <Typography variant="h6" gutterBottom>Agendar Test Drive</Typography>
            <TextField
              type="datetime-local"
              fullWidth
              value={date}
              onChange={(e) => setDate(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Endereço"
              fullWidth
              value={location.street || ''}
              onChange={(e) => setLocation({ ...location, street: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Número"
              fullWidth
              value={location.number || ''}
              onChange={(e) => setLocation({ ...location, number: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Cidade"
              fullWidth
              value={location.city || ''}
              onChange={(e) => setLocation({ ...location, city: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="CEP"
              fullWidth
              value={location.zipCode || ''}
              onChange={(e) => setLocation({ ...location, zipCode: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Complemento"
              fullWidth
              value={location.complement || ''}
              onChange={(e) => setLocation({ ...location, complement: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="outlined" onClick={handleCloseTestDriveModal}>Cancelar</Button>
              <Button variant="contained" color="primary" onClick={handleScheduleTestDrive}>Agendar</Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default CarDetail;