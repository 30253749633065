import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #536976, #292e49);
`;

export const LoginBox = styled.div`
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 500px) {
    padding: 30px 20px;
  }
`;

export const Title = styled.h2`
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 25px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 15px 12px 45px; /* Espaço extra para o ícone */
  border: none;
  border-radius: 8px;
  background: #f5f5f5;
  color: #333;
  font-size: 16px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
  box-sizing: border-box; /* Evita estouro do container */

  &:focus {
    outline: none;
    background: #fff;
    border: 2px solid #292e49;
    box-shadow: 0 0 10px rgba(30, 144, 255, 0.3);
  }

  @media (max-width: 500px) {
    font-size: 14px;
    padding: 10px 15px 10px 40px;
  }
`;


export const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #9a9a9a;
  font-size: 18px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: #292e49;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background: #292e49;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(30, 144, 255, 0.3);
  }

  @media (max-width: 500px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const Link = styled.a`
  color: #1e90ff;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  display: block;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: #187bcd;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const ErrorText = styled.p`
  color: #ff4d4d;
  margin-bottom: 20px;
  font-size: 14px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
