import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/system';
import { Box, Typography, useMediaQuery } from '@mui/material';
import animatedbackground from '../../assets/videos/animated_background.mp4';

// Estilo do container principal
const HeroSection = styled(Box)(({ theme }) => ({
  height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

// Estilo do vídeo de fundo
const BackgroundVideo = styled('video')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -1,
}));

// Estilo da camada de sobreposição
const VideoOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Contraste para o texto.
  zIndex: 0,
}));

// Estilo do conteúdo textual
const HeroContent = styled(Box)(() => ({
  zIndex: 1,
  padding: '0 20px',
  textShadow: '0 4px 8px rgba(0, 0, 0, 0.6)', // Destaque no texto.
  animation: 'fadeIn 1s ease-in-out', // Efeito de entrada suave.
}));

const HeroSectionComponent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setOffsetY(window.pageYOffset * 0.3);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeroSection>
      {/* Vídeo de fundo com paralaxe */}
      <BackgroundVideo
        autoPlay
        loop
        muted
        style={{ transform: `translateY(${offsetY}px)` }}
        disablePictureInPicture
        controlsList="nodownload nofullscreen noremoteplayback"
        onContextMenu={(e) => e.preventDefault()}
      >
        <source src={animatedbackground} type="video/mp4" />
      </BackgroundVideo>

      {/* Camada de sobreposição com paralaxe */}
      <VideoOverlay style={{ transform: `translateY(${offsetY}px)` }} />

      {/* Conteúdo textual */}
      <HeroContent>
        <Typography
          variant={isSmallScreen ? 'h4' : 'h2'}
          component="h1"
          fontWeight="bold"
          sx={{
            textTransform: 'uppercase',
            lineHeight: 1.2,
          }}
        >
          Road Cars Motors
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            mb: 2,
            maxWidth: '600px',
            mx: 'auto',
            opacity: 0.9,
          }}
        >
          A melhor solução para seu negócio automotivo. Consultoria especializada para venda, revendas e concessionárias.
        </Typography>
      </HeroContent>
    </HeroSection>
  );
};

export default HeroSectionComponent;
