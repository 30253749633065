import React, { useEffect } from 'react';
import { Container, Grid, Typography, Box, Card, CardContent, CardMedia, Button } from '@mui/material';
import founder from '../../assets/images/profile/founder.jpg';
import logo from '../../assets/images/logo.png';
import AOS from 'aos';
import CountUp from 'react-countup';
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 800, once: true });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '300px',
          py: 5,
          background: 'linear-gradient(to right, #536976, #292e49)',
          color: '#fff',
          textAlign: 'center',
        }}
        data-aos="zoom-in"
      >
        <Typography variant="h2" component="h1" gutterBottom sx={{ letterSpacing: '3px' }}>
          Sobre Nós
        </Typography>
      </Box>
      <Container sx={{ py: 6 }}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={6} data-aos="fade-right">
            <Typography variant="h4" gutterBottom fontWeight="bold">
              Nossa Missão
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ lineHeight: '1.7', fontSize: '18px' }}>
              A Road Cars Motors nasceu com a visão de transformar o mercado automotivo, oferecendo o melhor em venda e revenda de veículos personalizados, sempre focando na satisfação do cliente.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} data-aos="fade-left">
            <CardMedia
              component="img"
              image={logo}
              alt="Logo Road Cars"
              sx={{ borderRadius: 3, boxShadow: 3, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
            />
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: '#333', color: '#fff', py: 5 }}>
        <Container>
          <Typography variant="h4" align="center" gutterBottom data-aos="fade-up">
            Nossa Liderança
          </Typography>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} md={6} data-aos="fade-right">
              <Typography variant="h5">
                CEO e Fundador: Evandro Ferreira dos Santos
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ lineHeight: '1.7', fontSize: '18px', color: '#fff' }}>
                Evandro fundou a Road Cars Motors em 2010 com a missão de oferecer soluções automotivas inovadoras, trazendo sua paixão por carros para o mercado de revenda de veículos personalizados.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-left">
              <CardMedia
                component="img"
                image={founder}
                alt="Fundador"
                sx={{ borderRadius: 3, boxShadow: 3, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" align="center" gutterBottom fontWeight="bold" data-aos="fade-up">
          Nossos Números
        </Typography>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={3} data-aos="zoom-in">
            <Typography variant="h5" align="center">
              Veículos Vendidos
            </Typography>
            <Typography variant="h3" align="center" color="primary" fontWeight="bold">
              <CountUp end={1500} duration={3} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} data-aos="zoom-in">
            <Typography variant="h5" align="center">
              Clientes Satisfeitos
            </Typography>
            <Typography variant="h3" align="center" color="primary" fontWeight="bold">
              <CountUp end={350} duration={3} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} data-aos="zoom-in">
            <Typography variant="h5" align="center">
              Anos de Experiência
            </Typography>
            <Typography variant="h3" align="center" color="primary" fontWeight="bold">
              <CountUp end={10} duration={3} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} data-aos="zoom-in">
            <Typography variant="h5" align="center">
              Modelos Exclusivos Vendidos
            </Typography>
            <Typography variant="h3" align="center" color="primary" fontWeight="bold">
              <CountUp end={120} duration={3} />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Depoimentos como Carrossel */}
      <Box sx={{ py: 6, backgroundColor: '#f9f9f9' }}>
        <Container>
          <Typography variant="h4" align="center" gutterBottom fontWeight="bold" data-aos="fade-up">
            Depoimentos
          </Typography>
          <Slider {...settings}>
            <div>
              <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                <CardContent>
                  <Typography variant="body1" color="textSecondary" sx={{ lineHeight: '1.7' }}>
                    "A Road Cars Motors não só atendeu como superou minhas expectativas. A compra do meu carro foi tranquila e rápida."
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" mt={2}>
                    João Almeida
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                <CardContent>
                  <Typography variant="body1" color="textSecondary" sx={{ lineHeight: '1.7' }}>
                    "Fiquei muito satisfeito com o serviço prestado pela Road Cars Motors. Recomendo a todos que querem vender seu carro sem complicação."
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" mt={2}>
                    Maria Fernanda
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Slider>
        </Container>
      </Box>
    </div>
  );
};

export default AboutUs;