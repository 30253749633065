import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Switch,
  FormControlLabel,
  Typography,
  Box,
  Checkbox,
} from '@mui/material';
import { storage, firestore, auth } from '../../firebaseConfig';
import { addDoc, collection, updateDoc, doc } from 'firebase/firestore';
import { uploadCarImages } from '../../services/UploadImage';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import ColorSelection from '../../components/ColorSelection/ColorSelection';
import { brandOptions, fuelOptions, transmissionOptions, categoryOptions } from '../../data/options';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AddCar.css';

const AddCar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [carImages, setCarImages] = useState([]);
  const [selectedColor, setSelectedColor] = useState('Preto');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isArmored, setIsArmored] = useState(false);
  const [description, setDescription] = useState('');
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();

  const handleCategoryChange = (categoryValue) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryValue)
        ? prevSelected.filter((cat) => cat !== categoryValue)
        : [...prevSelected, categoryValue]
    );
  };

  const onSubmit = async (data) => {
    if (!auth.currentUser) {
      toast.error('Você precisa estar logado para adicionar um carro.');
      return;
    }

    if (carImages.length < 3) {
      toast.error('Adicione pelo menos 3 imagens.');
      return;
    }

    setIsLoading(true);

    try {
      const carRef = await addDoc(collection(firestore, 'cars'), {
        ...data,
        price: parseFloat(data.price),
        km: parseInt(data.km, 10),
        color: selectedColor,
        armored: isArmored,
        description,
        categories: selectedCategories,
        userId: auth.currentUser.uid,
        createdAt: new Date(),
      });

      const imageUrls = await uploadCarImages(carRef.id, carImages, storage);
      await updateDoc(doc(firestore, 'cars', carRef.id), { imageUrls });

      toast.success('Carro adicionado com sucesso!');

      setTimeout(() => {
        navigate('/catalog');
      }, 3000);
    } catch (error) {
      console.error('Erro ao adicionar carro:', error);
      toast.error('Erro ao adicionar carro.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="add-car-container" sx={containerStyle}>
      <Typography variant="h4" sx={titleStyle}>
        Adicionar Carro para Venda
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className="add-car-form">
        <Grid container spacing={2}>
          {/* Marca */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Marca"
              select
              fullWidth
              variant="outlined"
              {...register('brand', { required: 'Marca é obrigatória.' })}
              defaultValue="Toyota"
              error={!!errors.brand}
              helperText={errors.brand?.message}
            >
              {brandOptions.map(brand => (
                <MenuItem key={brand} value={brand}>
                  {brand}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Modelo */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Modelo"
              fullWidth
              variant="outlined"
              {...register('model', { required: 'Modelo é obrigatório.' })}
              error={!!errors.model}
              helperText={errors.model?.message}
            />
          </Grid>

          {/* Preço */}
          <Grid item xs={12} sm={6}>
            <NumericFormat
              label="Preço"
              fullWidth
              variant="outlined"
              customInput={TextField}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2} // Define 2 casas decimais
              fixedDecimalScale={true} // Mantém sempre 2 casas decimais visíveis
              {...register('price', { required: 'Preço é obrigatório.' })}
              error={!!errors.price}
              helperText={errors.price?.message}
              onValueChange={(values) => setValue('price', values.floatValue)}
            />
          </Grid>

          {/* Ano */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Ano"
              fullWidth
              variant="outlined"
              {...register('year', { required: 'Ano é obrigatório.' })}
              error={!!errors.year}
              helperText={errors.year?.message}
            />
          </Grid>

          {/* Motor */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Motor"
              fullWidth
              variant="outlined"
              {...register('motor', { required: 'Motor é obrigatório.' })}
              error={!!errors.motor}
              helperText={errors.motor?.message}
            />
          </Grid>

          {/* Combustível */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Combustível"
              select
              fullWidth
              variant="outlined"
              {...register('fuel', { required: 'Combustível é obrigatório.' })}
              defaultValue="Gasolina"
              error={!!errors.fuel}
              helperText={errors.fuel?.message}
            >
              {fuelOptions.map(fuel => (
                <MenuItem key={fuel} value={fuel}>
                  {fuel}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Câmbio */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Câmbio"
              select
              fullWidth
              variant="outlined"
              {...register('transmission', { required: 'Câmbio é obrigatório.' })}
              defaultValue="Automático"
              error={!!errors.transmission}
              helperText={errors.transmission?.message}
            >
              {transmissionOptions.map(transmission => (
                <MenuItem key={transmission} value={transmission}>
                  {transmission}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Quilometragem */}
          <Grid item xs={12} sm={6}>
            <NumericFormat
              label="KM Rodados"
              fullWidth
              variant="outlined"
              customInput={TextField}
              thousandSeparator="."
              decimalSeparator=","
              suffix=" km"
              {...register('km', { required: 'Quilometragem é obrigatória.' })}
              error={!!errors.km}
              helperText={errors.km?.message}
              onValueChange={(values) => setValue('km', values.value)}
              allowNegative={false}
              isNumericString
            />
          </Grid>

          {/* Descrição */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Descrição
            </Typography>
            <ReactQuill value={description} onChange={setDescription} />
          </Grid>

          {/* Cor */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Cor
            </Typography>
            <ColorSelection selectedColor={selectedColor} onColorSelected={setSelectedColor} />
          </Grid>

          {/* Categorias */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={sectionTitleStyle}>
              Categoria
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {categoryOptions.map((category) => (
                <FormControlLabel
                  key={category.value}
                  control={
                    <Checkbox
                      checked={selectedCategories.includes(category.value)}
                      onChange={() => handleCategoryChange(category.value)}
                      color="primary"
                    />
                  }
                  label={category.label}
                />
              ))}
            </Box>
          </Grid>
          {/* Blindado */}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch checked={isArmored} onChange={() => setIsArmored(!isArmored)} />}
              label="Blindado"
            />
          </Grid>

          {/* Seletor de imagens */}
          <Grid item xs={12}>
            <ImagePicker carImages={carImages} setCarImages={setCarImages} isEditMode={false} />
          </Grid>

          {/* Botão de envio */}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={submitButtonStyle(isLoading)}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Salvar Carro'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddCar;

// Estilos e opções
const containerStyle = {
  padding: '20px',
  backgroundColor: '#F4F6F8',
  borderRadius: '12px',
};

const titleStyle = {
  mb: 3,
  textAlign: 'center',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
  color: '#333',
};

const sectionTitleStyle = {
  mb: 2,
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
  color: '#333',
};

const submitButtonStyle = (isLoading) => ({
  backgroundColor: isLoading ? '#ccc' : '#4a90e2',
  color: 'white',
  borderRadius: '25px',
  padding: '10px 0',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: isLoading ? '#ccc' : '#357ab7',
  },
});