import React from 'react';
import { Container, Grid, Typography, Box, IconButton, Link } from '@mui/material';
import { Instagram, WhatsApp, Home, Email, Business } from '@mui/icons-material';
import logo from '../../assets/images/logo.png';
import './Footer.css';

const Footer = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: 'black', color: 'white', pt: 4 }}>
      <Container>
        <Grid container spacing={4} justifyContent="center">
          {/* Logo */}
          <Grid item xs={12} md={4} lg={3} textAlign="center">
            <img src={logo} alt="Logo" className="footer-logo mb-3" style={{ width: '100%', maxWidth: '200px' }} />
            <Typography variant="body1" className="footer-tagline">A excelência em consultoria automotiva.</Typography>
          </Grid>

          {/* Sobre */}
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" className="footer-title mb-3">Sobre Nós</Typography>
            <Typography variant="body2" className="footer-text">
              Na Road Cars Consulting, somos especializados em oferecer soluções automotivas sob medida, com compromisso e transparência.
            </Typography>
          </Grid>

          {/* Redes sociais */}
          <Grid item xs={12} md={4} lg={3} textAlign="center">
            <Typography variant="h6" className="footer-title mb-3">Redes Sociais</Typography>
            <Box className="footer-socials">
              <IconButton component="a" href="https://www.instagram.com/roadcars.consulting/" className="footer-icon" sx={{ color: 'white' }}>
                <Instagram fontSize="large" />
              </IconButton>
              <IconButton component="a" href="https://wa.me/5511915619513" className="footer-icon" sx={{ color: 'white' }}>
                <WhatsApp fontSize="large" />
              </IconButton>
            </Box>
          </Grid>

          {/* Contato */}
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" className="footer-title mb-3">Contato</Typography>
            <Typography variant="body2" className="footer-text">
              <Home sx={{ mr: 1 }} /> São Paulo, SP - Brasil
            </Typography>
            <Typography variant="body2" className="footer-text">
              <Email sx={{ mr: 1 }} />
              <Link href="mailto:roadcars.consulting@outlook.com" color="inherit" underline="none">
                roadcars.consulting@outlook.com
              </Link>
            </Typography>
            <Typography variant="body2" className="footer-text">
              <Business sx={{ mr: 1 }} /> CNPJ: 45.925.693/0001-61
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', py: 3, textAlign: 'center' }}>
        <Typography variant="body2" className="mb-0">© 2021 Road Cars Consulting. Todos os direitos reservados.</Typography>
      </Box>
    </Box>
  );
};

export default Footer;