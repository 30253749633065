// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAhRHb2aO63ZazUdnfSeP8Xi7EJPTf-UMk",
  authDomain: "roadcarsapp.firebaseapp.com",
  projectId: "roadcarsapp",
  storageBucket: "roadcarsapp.appspot.com",
  messagingSenderId: "1056284825768",
  appId: "1:1056284825768:web:15823d2ebfb9e605db4a9d",
  measurementId: "G-C3JPSX2KFG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { firestore, auth, storage };