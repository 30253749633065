import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Avatar, Menu, MenuItem, Tooltip, Divider, useMediaQuery, Drawer, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { FaSignInAlt, FaUserCircle, FaSignOutAlt, FaCar, FaInfoCircle } from 'react-icons/fa';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/logo.png';
import { useTheme } from '@mui/material/styles';

const Header = () => {
    const [user, setUser] = useState(null);
    const [profilePic, setProfilePic] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();
    const firestore = getFirestore();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
                const userDoc = await getDoc(doc(firestore, 'users', user.uid));
                if (userDoc.exists()) setProfilePic(userDoc.data().profileImageUrl);
            } else {
                setUser(null);
                setProfilePic('');
            }
        });
        return () => unsubscribe();
    }, [auth, firestore]);

    const handleLogout = async () => {
        await signOut(auth);
        navigate('/login');
        toggleMobileMenu(); // Fechar o menu ao fazer logout
    };

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);
    const toggleMobileMenu = () => setMobileOpen(!mobileOpen);

    const navigateTo = (path) => {
        handleMenuClose();
        toggleMobileMenu(); // Fecha o drawer ao navegar para outra página
        navigate(path);
    };

    const renderUserActions = () =>
        user ? (
            <Tooltip title={`Olá, ${user.displayName || 'Usuário'}`} arrow>
                <IconButton onClick={handleMenuOpen} sx={{ padding: 0 }}>
                    <Avatar src={profilePic} sx={avatarStyles} />
                </IconButton>
            </Tooltip>
        ) : (
            <IconButton color="inherit" component={Link} to="/login">
                <FaSignInAlt size={24} />
            </IconButton>
        );

    const renderUserMenu = () => (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} PaperProps={menuStyles}>
            <MenuItem onClick={() => navigateTo('/profile')}>
                <FaUserCircle size={18} sx={{ mx: 1 }} />
                &nbsp;
                Perfil
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
                <FaSignOutAlt size={18} sx={{ mx: 1 }} />
                &nbsp;
                Logout
            </MenuItem>
        </Menu>
    );

    const renderMenuItems = () => (
        <>
            <ListItem button component={Link} to="/catalog" onClick={toggleMobileMenu} sx={menuItemStyles}>
                <FaCar size={20} sx={{ mx: 1 }} />
                &nbsp;
                Catálogo
            </ListItem>
            <ListItem button component={Link} to="/about" onClick={toggleMobileMenu} sx={menuItemStyles}>
                <FaInfoCircle size={20} sx={{ mx: 1 }} />
                &nbsp;
                Quem &nbsp;somos
            </ListItem>
        </>
    );

    const renderLoginOrProfile = () => {
        if (user) {
            return (
                <>
                    <Box sx={{ textAlign: 'center', my: 2 }}>
                        <Avatar
                            src={profilePic}
                            alt={user.displayName || 'Usuário'}
                            sx={{ width: 60, height: 60, margin: '0 auto' }}
                            component={Link}
                            to="/profile"
                            onClick={toggleMobileMenu}
                        />
                        <Typography variant="h6" color="#fff" mt={1}>
                            Bem-vindo de volta, {user.displayName || 'Usuário'}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            );
        } else {
            return (
                <ListItem button component={Link} to="/login" onClick={toggleMobileMenu} sx={menuItemStyles}>
                    <FaSignInAlt size={20} sx={{ mx: 1 }} />
                    &nbsp;
                    Fazer Login
                </ListItem>
            );
        }
    };

    const avatarStyles = {
        width: 40,
        height: 40,
        border: '2px solid #fff',
        '&:hover': { borderColor: theme.palette.primary.main, transform: 'scale(1.05)' },
    };

    const menuStyles = {
        sx: {
            mt: '1rem',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '1rem',
            minWidth: '4rem',
            backgroundColor: 'white',
            color: '#000000',
        },
    };

    const menuItemStyles = {
        color: '#fff',
        '&:hover': { color: theme.palette.primary.main },
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#000', px: 2 }}>
            <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Button color="inherit" component={Link} to="/" sx={{ padding: 0 }}>
                    <Box component="img" src={logo} alt="Road Cars Motors" sx={{ height: 40, mr: 2, '&:hover': { transform: 'scale(1.1)' } }} />
                </Button>

                {isMobile ? (
                    <>
                        <IconButton color="inherit" onClick={toggleMobileMenu}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={mobileOpen} onClose={toggleMobileMenu} sx={{ '& .MuiDrawer-paper': { width: 250, backgroundColor: '#000' } }}>
                            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                                <Box component="img" src={logo} alt="Road Cars Motors" sx={{ height: 40 }} />
                                <IconButton onClick={toggleMobileMenu} sx={{ color: '#fff' }}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Divider />
                            <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {renderLoginOrProfile()}
                                {renderMenuItems()}
                                {user && (
                                    <ListItem button onClick={handleLogout} sx={menuItemStyles}>
                                        <FaSignOutAlt size={20} sx={{ mx: 1 }} />
                                        &nbsp;
                                        Logout
                                    </ListItem>
                                )}
                            </List>
                        </Drawer>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {renderMenuItems()}
                        {renderUserActions()}
                    </Box>
                )}
            </Toolbar>
            {renderUserMenu()}
        </AppBar>
    );
};

export default Header;
