import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Catalog from './pages/Catalog/Catalog';
import CarDetail from './pages/CarDetail/CarDetail';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register/Register';
import AddCar from './pages/AddCar/AddCar';
import EditCarScreen from './pages/EditCar/EditCar';
import { AuthProvider } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AboutUs from './pages/About/About';

const App = () => {
  // URL base para gerar URLs absolutas
  const baseUrl = window.location.origin;

  return (
    <>
      <Helmet>
        <title>Road Cars Motors</title>
        <meta name="description" content="A melhor solução para seu negócio automotivo." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:image" content={`${baseUrl}/assets/icons/icon.png`} />
        <meta property="fb:app_id" content="1291552575609078" />
      </Helmet>
      <AuthProvider>
        <ToastContainer />
        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/car/:id" element={<CarDetail />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/add-car" element={<AddCar />} />
            <Route path="/edit/:carId" element={<EditCarScreen />} />
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>
    </>
  );
};

export default App;
