import React from 'react';
import { Box, Typography, Grid, Card, CardContent, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import carro_eletrico from '../assets/images/categorias/carro_eletrico.jpg';
import carro_esportivo from '../assets/images/categorias/carro_esportivo.jpg';
import carro_luxo from '../assets/images/categorias/carro_luxo.png';
import carro_suv from '../assets/images/categorias/carro_suv.jpg';
import carro_compacto from '../assets/images/categorias/carro_compacto.jpg';
import carro_sedan from '../assets/images/categorias/carro_sedan.jpg';
import carro_hatch from '../assets/images/categorias/carro_hatch.png';
import HeroSectionComponent from '../components/HeroSection/HeroSection';

import Slider from 'react-slick';

const ServiceCard = styled(Card)(({ theme }) => ({
  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '20px',
  transition: 'transform 0.4s ease, box-shadow 0.4s ease',
  '&:hover': {
    transform: 'scale(1.08)',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
  },
  textAlign: 'center',
  background: 'linear-gradient(135deg, #f0f4f8 30%, #c3cfe2 100%)',
}));

const CategoryCard = styled(Card)(({ theme }) => ({
  height: '25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  background: '#000',
  borderRadius: '20px',
  margin: '0 10px',
  textAlign: 'center',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'transform 0.4s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    height: '50%',
    background: '#000',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '20px',
  color: '#fff',
  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CategoryImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
});

const HomePage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/catalog?category=${category}`);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: isSmallScreen ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: !isSmallScreen,
  };

  const categories = [
    { name: 'Eletrico', image: carro_eletrico, description: 'Carros com tecnologia de emissão zero.' },
    { name: 'Esportivo', image: carro_esportivo, description: 'Alta performance e design arrojado.' },
    { name: 'Luxo', image: carro_luxo, description: 'O máximo em conforto e elegância.' },
    { name: 'SUV', image: carro_suv, description: 'Espaço, segurança e estilo.' },
    { name: 'Compacto', image: carro_compacto, description: 'Praticidade e eficiência para o dia a dia.' },
    { name: 'Sedan', image: carro_sedan, description: 'Conforto e espaço para a família.' },
    { name: 'Hatch', image: carro_hatch, description: 'Compacto e versátil para a cidade.' },
  ];

  return (
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <HeroSectionComponent />
        {/* Destaques */}
        <Box sx={{ py: 8, backgroundColor: '#fff' }}>
          <Typography variant="h4" textAlign="center" fontWeight="bold" gutterBottom>
            Carros em Destaque
          </Typography>
          <Slider {...sliderSettings}>
            {categories.map((category, index) => (
              <Box key={index} sx={{ padding: '10px' }}>
                <CategoryCard onClick={() => handleCategoryClick(category.name)}>
                  <CategoryImage src={category.image} alt={category.name} />
                  <TextContainer>
                    <Typography variant="h5" fontWeight="bold">
                      {category.name}
                    </Typography>
                    <Typography variant="body2">{category.description}</Typography>
                  </TextContainer>
                </CategoryCard>
              </Box>
            ))}
          </Slider>
        </Box>
        {/* Serviços */}
        <Box sx={{ py: 8 }}>
          <Typography variant="h4" textAlign="center" fontWeight="bold" gutterBottom>
            Nossos Serviços
          </Typography>
          <Typography variant="body1" textAlign="center" mb={6}>
            Oferecemos uma gama de serviços sob medida para a indústria automotiva.
          </Typography>
          <Grid container spacing={4}>
            {['Análise de Mercado', 'Consultoria Operacional', 'Estratégia de Vendas'].map((servico, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ServiceCard>
                  <CardContent>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      {servico}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {servico === 'Análise de Mercado' && 'Insights profundos do setor para orientar suas decisões.'}
                      {servico === 'Consultoria Operacional' && 'Aumente a eficiência operacional com aconselhamento especializado.'}
                      {servico === 'Estratégia de Vendas' && 'Estratégias sob medida para maximizar seu potencial de vendas.'}
                    </Typography>
                  </CardContent>
                </ServiceCard>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Depoimentos */}
        <Box sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
          <Typography variant="h4" textAlign="center" fontWeight="bold" gutterBottom>
            Depoimentos
          </Typography>
          <Typography variant="body1" textAlign="center" mb={6}>
            O que nossos clientes têm a dizer.
          </Typography>
          <Slider {...sliderSettings}>
            {['Depoimento 1', 'Depoimento 2', 'Depoimento 3'].map((depoimento, index) => (
              <Card key={index} sx={{ padding: '20px', margin: '0 10px' }}>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    {depoimento === 'Depoimento 1' && '"A Roadcars transformou nosso negócio com insights valiosos."'}
                    {depoimento === 'Depoimento 2' && '"Equipe extremamente profissional e comprometida!"'}
                    {depoimento === 'Depoimento 3' && '"Excelência no atendimento e consultoria estratégica."'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Cliente satisfeito
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Slider>
        </Box>
      </Box>
  );
};

export default HomePage;