import React, { useState, useEffect } from 'react';
import {
  Avatar, Button, Card, CardContent, Grid, Typography, Chip, Box, CircularProgress, 
  LinearProgress, Modal, TextField, Divider, Tooltip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaStar, FaCamera,FaPlusCircle } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, query, where, collection, getDocs, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import CarCard from '../../components/CarCard/CarCard';
import './Profile.css';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [addedCars, setAddedCars] = useState([]);
  const [favoriteCars, setFavoriteCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedEmail, setEditedEmail] = useState('');
  const [editProfileTooltip, setEditProfileTooltip] = useState("Editar perfil");

  const auth = getAuth();
  const firestore = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();
  const user = auth.currentUser;

  const fetchAddedCars = async () => {
    const carsQuery = query(collection(firestore, 'cars'), where('userId', '==', user.uid));
    const carSnapshot = await getDocs(carsQuery);
    const cars = carSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setAddedCars(cars);
  };

  const fetchFavoriteCars = async (favoritedCarIds) => {
    if (favoritedCarIds && favoritedCarIds.length > 0) {
      const favoriteQuery = query(collection(firestore, 'cars'), where('__name__', 'in', favoritedCarIds));
      const favoriteSnapshot = await getDocs(favoriteQuery);
      const cars = favoriteSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFavoriteCars(cars);
    }
  };

  const fetchUserData = async () => {
    if (user) {
      const userDoc = await getDoc(doc(firestore, 'users', user.uid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        setUserData(data);
        setProfileImage(data.profileImageUrl || '');
        setEditedName(data.displayName);
        setEditedEmail(data.email);

        if (data.isVendor) {
          fetchAddedCars();
        }

        if (data.favoritedCars) {
          fetchFavoriteCars(data.favoritedCars);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      await updateDoc(doc(firestore, 'users', user.uid), {
        profileImageUrl: downloadURL,
      });
      setProfileImage(downloadURL);
      setUploading(false);
    }
  };

  const handleEditSubmit = async () => {
    await updateDoc(doc(firestore, 'users', user.uid), {
      displayName: editedName,
      email: editedEmail,
    });
    setUserData({ ...userData, displayName: editedName, email: editedEmail });
    setEditModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  const handleProfileHover = (state) => {
    setEditProfileTooltip(state ? "Clique para editar" : "Editar perfil");
  }

  const handleAddCarClick = () => {
    navigate('/add-car');
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={4} sx={{ padding: '20px'}}>
      <Grid item xs={12} md={4}>
        <Card 
          sx={{ 
            textAlign: 'center', 
            padding: '20px', 
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)', 
            background: 'linear-gradient(145deg, #e0e5ec, #ffffff)' 
          }}
        >
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <Avatar 
              src={profileImage} 
              sx={{ 
                width: 150, 
                height: 150, 
                margin: 'auto', 
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': { transform: 'scale(1.05)' }
              }} 
            />
            <label htmlFor="upload-button">
              <FaCamera style={{ 
                position: 'absolute', 
                bottom: 0, 
                right: '10%', 
                cursor: 'pointer', 
                fontSize: '1.5em',
                color: '#1976d2',
                opacity: 0.8 
              }} />
              <input type="file" id="upload-button" style={{ display: 'none' }} onChange={handleImageUpload} />
            </label>
            {uploading && <LinearProgress sx={{ width: '100%', mt: 1 }} />}
          </Box>
          <CardContent>
            {userData && (
              <>
                <Typography variant="h5" gutterBottom sx={{ color: '#333' }}>{userData.displayName}</Typography>
                <Typography variant="body1" color="textSecondary">{userData.email}</Typography>
                {userData.isVendor && <Chip label="Vendedor" color="primary" icon={<FaStar />} sx={{ mt: 2 }} />}
              </>
            )}
          </CardContent>
          <Tooltip title={editProfileTooltip} arrow>
            <Button 
              variant="contained" 
              color="primary" 
              sx={{ marginTop: '10px' }} 
              onClick={handleOpenModal}
              onMouseEnter={() => handleProfileHover(true)}
              onMouseLeave={() => handleProfileHover(false)}
            >
              Editar Perfil
            </Button>
          </Tooltip>
        </Card>
      </Grid>

      <Grid item xs={12} md={8}>
        <Card sx={{ padding: '20px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)', background: 'linear-gradient(145deg, #f0f4f8, #ffffff)' }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#1976d2' }}>
            {userData.isVendor ? 'Meus Carros' : 'Meus Favoritos'}
          </Typography>
          
          {userData.isVendor && (
            <>
              <Button
                onClick={handleAddCarClick}
                variant="outlined"
                startIcon={<FaPlusCircle />}
                sx={{ mb: 2 }}
                color="primary"
              >
                Adicionar Novo Carro
              </Button>

              <Grid container spacing={2}>
                {addedCars.length ? (
                  addedCars.map((car) => (
                    <Grid item xs={12} sm={6} md={4} key={car.id}>
                      <CarCard car={car} />
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">Nenhum carro adicionado.</Typography>
                )}
              </Grid>
            </>
          )}

          <Divider sx={{ my: 3 }} />

          <Typography variant="h4" gutterBottom sx={{ color: '#1976d2' }}>Carros Favoritos</Typography>
          <Grid container spacing={2}>
            {favoriteCars.length ? (
              favoriteCars.map((car) => (
                <Grid item xs={12} sm={6} md={4} key={car.id}>
                  <CarCard car={car} />
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="textSecondary">Nenhum carro favoritado.</Typography>
            )}
          </Grid>
        </Card>
      </Grid>

      {/* Modal para Editar Informações */}
      <Modal
        open={editModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-profile-modal"
        aria-describedby="modal-to-edit-profile-information"
      >
        <Box sx={{ ...modalStyle }}>
          <Typography variant="h6" component="h2">Editar Perfil</Typography>
          <TextField
            fullWidth
            label="Nome"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            value={editedEmail}
            onChange={(e) => setEditedEmail(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleEditSubmit}
          >
            Salvar Alterações
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default Profile;
