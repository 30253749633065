import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import CarCard from '../CarCard/CarCard';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const CarList = ({ filters }) => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);

      try {
        const carCollection = collection(firestore, 'cars');
        const querySnapshot = await getDocs(carCollection);

        let carData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        carData = applyFilters(carData, filters);

        setCars(carData);
      } catch (error) {
        console.error('Erro ao buscar carros:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCars();
  }, [filters, firestore]);

  const applyFilters = (carData, filters) => {
    let filteredCars = carData;

    if (filters.brand) {
      filteredCars = filteredCars.filter(car => car.brand === filters.brand);
    }

    if (filters.model) {
      filteredCars = filteredCars.filter(car => car.model.toLowerCase().includes(filters.model.toLowerCase()));
    }

    if (filters.year) {
      filteredCars = filteredCars.filter(car => car.year === String(filters.year));
    }

    if (filters.priceRange && filters.priceRange.length === 2) {
      const [minPrice, maxPrice] = filters.priceRange;
      filteredCars = filteredCars.filter(car => car.price >= minPrice && car.price <= maxPrice);
    }

    if (filters.color) {
      filteredCars = filteredCars.filter(car => car.color === filters.color);
    }

    if (filters.fuel) {
      filteredCars = filteredCars.filter(car => car.fuel === filters.fuel);
    }

    if (filters.transmission) {
      filteredCars = filteredCars.filter(car => car.transmission === filters.transmission);
    }

    if (filters.motor) {
      filteredCars = filteredCars.filter(car => car.motor === filters.motor);
    }

    if (filters.armored !== undefined && filters.armored !== '') {
      filteredCars = filteredCars.filter(car => car.armored === (filters.armored === 'Sim'));
    }

    if (filters.category && filters.category.length > 0) {
      filteredCars = filteredCars.filter(car => 
        car.categories && car.categories.some(cat => filters.category.includes(cat))
      );
    }

    return filteredCars;
  };

  return (
    <Box sx={{ padding: '20px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      ) : cars.length > 0 ? (
        <Grid container spacing={4}>
          {cars.map(car => (
            <Grid item xs={12} sm={6} md={4} key={car.id} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CarCard car={car} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '300px', textAlign: 'center' }}>
          <SentimentDissatisfiedIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" color="text.secondary">
            Nenhum carro encontrado
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Tente ajustar os filtros ou verifique novamente mais tarde.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CarList;