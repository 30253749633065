import React, { useState, useEffect } from 'react';
import {
  Box, Typography, TextField, MenuItem, Grid, Fab, Slider, InputAdornment, Checkbox, FormControlLabel,
  FormGroup, Button, Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import CarList from '../../components/CarList/CarList';
import styled from 'styled-components';
import ColorSelection from '../../components/ColorSelection/ColorSelection';
import SearchIcon from '@mui/icons-material/Search';
import { brandOptions, categoryOptions, fuelOptions, transmissionOptions, colors } from '../../data/options';
import { useTheme, useMediaQuery } from '@mui/material';

const FilterContainer = styled(Box)`
  padding: 24px;
  background-color: #fdfdfd;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Catalog = () => {
  const [filters, setFilters] = useState({
    brand: '',
    model: '',
    year: '',
    priceRange: [0, 800000],
    color: '',
    transmission: '',
    motor: '',
    fuel: '',
    armored: '',
    category: [],
  });

  const [isVendor, setIsVendor] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const checkIfVendor = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = doc(firestore, 'users', user.uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setIsVendor(userData.isVendor || false);
          }
        } catch (error) {
          console.error('Erro ao buscar informações do usuário:', error);
        }
      }
    };

    checkIfVendor();
  }, [auth]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    if (category) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        category: [category],
      }));
    }
  }, [location.search]);

  const handlePriceChange = (event, newValue) => {
    setFilters({
      ...filters,
      priceRange: newValue,
    });
  };

  const handleColorSelected = (colorName) => {
    setFilters({
      ...filters,
      color: colorName,
    });
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleCategoryChange = (category) => {
    setFilters((prevFilters) => {
      const isCategorySelected = prevFilters.category.includes(category);
      const updatedCategories = isCategorySelected
        ? prevFilters.category.filter((cat) => cat !== category)
        : [...prevFilters.category, category];

      return { ...prevFilters, category: updatedCategories };
    });
  };

  const handleAddCarClick = () => {
    navigate('/add-car');
  };

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Box
        sx={{
          background: 'linear-gradient(to right,#4a536b , #2e354a)',
          py: 5,
          textAlign: 'center',
          color: 'white',
        }}
      >
        <Typography variant="h3" className="animate__animated animate__fadeInDown">
          Catálogo de Carros
        </Typography>
        <Typography variant="subtitle1" className="animate__animated animate__fadeInUp">
          Selecione um Carro Abaixo
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ marginTop: '16px' }}>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center', mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={filtersOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={toggleFilters}
              sx={{
                backgroundColor: '#4a536b',
                '&:hover': {
                  backgroundColor: '#2e354a',
                },
              }}
            >
              Filtros
            </Button>
          </Box>
          <Collapse in={filtersOpen || !isSmallScreen}>
            <FilterContainer>
              <Typography variant="h6" gutterBottom>Filtros de Busca</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Marca"
                    name="brand"
                    value={filters.brand}
                    onChange={handleFilterChange}
                    select
                    sx={{ borderRadius: '8px', backgroundColor: '#f0f0f0' }}
                  >
                    <MenuItem value="">Todas</MenuItem>
                    {brandOptions.map((brand) => (
                      <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Buscar Modelo"
                    name="model"
                    value={filters.model}
                    onChange={handleFilterChange}
                    sx={{ borderRadius: '8px', backgroundColor: '#f0f0f0' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Ano"
                    name="year"
                    value={filters.year}
                    onChange={handleFilterChange}
                    type="number"
                    sx={{ borderRadius: '8px', backgroundColor: '#f0f0f0' }}
                    placeholder="Insira o ano"
                    InputProps={{
                      inputProps: { min: 1900, max: new Date().getFullYear() }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Preço
                  </Typography>
                  <Slider
                    value={filters.priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={500000}
                    step={10000}
                    marks={[
                      { value: 0, label: 'Min' },
                      { value: 150000, label: 'R$150 mil' },
                      { value: 250000, label: 'R$250 mil' },
                      { value: 350000, label: 'R$350 mil' },
                      { value: 500000, label: 'Max' },
                    ]}
                    sx={{
                      color: '#4a536b',
                      '& .MuiSlider-thumb': {
                        backgroundColor: '#fff',
                        border: '3px solid #4a536b',
                        '&:hover, &.Mui-focusVisible': {
                          boxShadow: '0px 0px 0px 8px rgba(74, 83, 107, 0.16)',
                        },
                        '&:active': {
                          boxShadow: '0px 0px 0px 14px rgba(74, 83, 107, 0.24)',
                        },
                      },
                      '& .MuiSlider-track': {
                        backgroundColor: '#4a536b',
                        height: 6,
                        borderRadius: 4,
                      },
                      '& .MuiSlider-rail': {
                        backgroundColor: '#e0e0e0',
                        height: 6,
                        borderRadius: 4,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Categorias</Typography>
                  <FormGroup>
                    {categoryOptions.map((category) => (
                      <FormControlLabel
                        key={category.value}
                        control={
                          <Checkbox
                            checked={filters.category.includes(category.value)}
                            onChange={() => handleCategoryChange(category.value)}
                          />
                        }
                        label={category.label}
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Transmissão"
                    name="transmission"
                    value={filters.transmission}
                    onChange={handleFilterChange}
                    select
                    sx={{ borderRadius: '8px', backgroundColor: '#f0f0f0' }}
                  >
                    <MenuItem value="">Todas</MenuItem>
                    {transmissionOptions.map((trans) => (
                      <MenuItem key={trans} value={trans}>{trans}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Combustível"
                    name="fuel"
                    value={filters.fuel}
                    onChange={handleFilterChange}
                    select
                    sx={{ borderRadius: '8px', backgroundColor: '#f0f0f0' }}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    {fuelOptions.map((fuel) => (
                      <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Cor</Typography>
                  <ColorSelection
                    selectedColor={filters.color}
                    onColorSelected={handleColorSelected}
                    colorOptions={colors}
                  />
                </Grid>
              </Grid>
            </FilterContainer>
          </Collapse>
        </Grid>
        <Grid item xs={12} md={9}>
          <CarList filters={filters} />
        </Grid>
      </Grid>

      {isVendor && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            backgroundColor: '#4a536b',
            '&:hover': {
              backgroundColor: '#2e354a',
            },
          }}
          onClick={handleAddCarClick}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  );
};

export default Catalog;