import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, IconButton, useTheme } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { firestore } from '../../firebaseConfig';
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';

const CarCard = ({ car }) => {
  const { currentUser } = useAuth();
  const [isFavorite, setIsFavorite] = useState(false);
  const theme = useTheme();

  const fetchIsFavorite = async () => {
    if (currentUser) {
      const userRef = doc(firestore, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const favoritedCars = userDoc.data().favoritedCars || [];
        setIsFavorite(favoritedCars.includes(car.id));
      }
    }
  };

  const handleFavoriteToggle = async (e) => {
    e.preventDefault();
    if (!currentUser) return;

    const userRef = doc(firestore, 'users', currentUser.uid);
    try {
      if (isFavorite) {
        await updateDoc(userRef, { favoritedCars: arrayRemove(car.id) });
      } else {
        await updateDoc(userRef, { favoritedCars: arrayUnion(car.id) });
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Erro ao atualizar favoritos: ', error);
    }
  };

  useEffect(() => {
    fetchIsFavorite();
  }, [currentUser]);

  return (
    <Card
      component={Link}
      to={`/car/${car.id}`}
      sx={{
        maxWidth: '100%',
        width: '100%',
        borderRadius: 3,
        margin: '20px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        textDecoration: 'none',
        color: 'inherit',
        transition: 'transform 0.3s ease-in-out',
        backgroundColor: theme.palette.background.default,
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.15)',
        },
        '@media (max-width: 600px)': { 
          margin: '10px',
          boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          image={car.imageUrls[0]}
          alt={`${car.brand} ${car.model}`}
          sx={{
            height: 220,
            objectFit: 'cover',
            borderRadius: '12px 12px 0 0',
            '@media (max-width: 600px)': {
              height: 180,
            },
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '40%',
            background: 'linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))',
            borderRadius: '12px 12px 0 0',
          }}
        />
        <Box sx={{ position: 'absolute', top: 10, right: 10, display: 'flex' }}>
          {currentUser && (
            <IconButton
              sx={{
                color: isFavorite ? 'red' : 'white',
                '&:hover': { color: 'red' },
              }}
              onClick={handleFavoriteToggle}
            >
              {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
          )}
        </Box>
      </Box>
      <CardContent sx={{ padding: '16px', backgroundColor: theme.palette.background.paper }}>
        <Typography fontWeight="bold" gutterBottom sx={{ color: theme.palette.primary.main }}>
          {car.brand} {car.model}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap', color: theme.palette.text.primary }}
        >
          {car.motor}&nbsp;|&nbsp;
          {car.transmission}&nbsp;|&nbsp;
          {car.fuel}&nbsp;|&nbsp;
          {car.armored && (
            <>
              Blindado
            </>
          )}
        </Typography>
        <br/>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ color: theme.palette.text.primary }}
          >
            {car.year}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ color: theme.palette.text.primary }}
          >
            {car.km} km
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            marginTop: 1,
            fontSize: '1.2rem',
            '@media (max-width: 600px)': {
              fontSize: '1rem',
            },
          }}
        >
          R$ {parseFloat(car.price).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CarCard;
