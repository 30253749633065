import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUserAlt, FaLock, FaEnvelope, FaPhone } from 'react-icons/fa';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #292e49, #536976);
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: auto;
    padding: 40px 20px;
  }
`;

const RegisterBox = styled.div`
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 500px) {
    padding: 30px 20px;
  }
`;

export const Title = styled.h2`
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 25px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 15px 12px 45px; /* Espaço extra para o ícone */
  border: none;
  border-radius: 8px;
  background: #f5f5f5;
  color: #333;
  font-size: 16px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
  box-sizing: border-box; /* Evita estouro do container */

  &:focus {
    outline: none;
    background: #fff;
    border: 2px solid #1e90ff;
    box-shadow: 0 0 10px rgba(30, 144, 255, 0.3);
  }

  @media (max-width: 500px) {
    font-size: 14px;
    padding: 10px 15px 10px 40px;
  }
`;


export const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #9a9a9a;
  font-size: 18px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #292e49;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #292e49;
  }

  @media (max-width: 500px) {
    font-size: 14px; /* Ajusta o tamanho da fonte do botão em telas menores */
    padding: 10px 8px; /* Ajusta o padding em dispositivos pequenos */
  }
`;

const Link = styled.a`
  color: #1e90ff;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  display: block;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    font-size: 12px; /* Ajusta o tamanho da fonte para dispositivos menores */
  }
`;

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const firestore = getFirestore();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);

      await setDoc(doc(firestore, 'users', userCredential.user.uid), {
        displayName: name,
        email: email,
        phone: phone,
        createdAt: Timestamp.now(),
        profileImageUrl: '',
        isVendor: false,
        favoritedCars: [],
      });

      alert('Registro bem-sucedido! Verifique seu e-mail.');
      navigate('/email-verification');
    } catch (error) {
      setError(`Falha no registro: ${error.message}`);
    }
  };

  return (
    <RegisterContainer>
      <RegisterBox>
        <Title>Criar Conta</Title>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleRegister}>
          <InputContainer>
            <Icon>
              <FaUserAlt />
            </Icon>
            <Input
              type="text"
              placeholder="Nome Completo"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <Icon>
              <FaEnvelope />
            </Icon>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <Icon>
              <FaPhone />
            </Icon>
            <Input
              type="tel"
              placeholder="Telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <Icon>
              <FaLock />
            </Icon>
            <Input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </InputContainer>
          <Button type="submit">Registrar</Button>
        </form>
        <Link onClick={() => navigate('/login')}>Já tem uma conta? Faça login</Link>
      </RegisterBox>
    </RegisterContainer>
  );
};

export default Register;
