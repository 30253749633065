import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, IconButton, Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './ImagePicker.css';

const ImagePicker = ({ carImages, carImageUrls, setCarImages, setCarImageUrls, isEditMode }) => {
  // Adiciona novas imagens ao array
  const onDrop = useCallback((acceptedFiles) => {
    setCarImages([
      ...carImages,
      ...acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      ),
    ]);
  }, [carImages, setCarImages]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  });

  // Remove uma imagem carregada
  const removeLocalImage = (index) => {
    setCarImages(carImages.filter((_, i) => i !== index));
  };

  // Remove uma URL de imagem salva
  const removeSavedImageUrl = (index) => {
    setCarImageUrls(carImageUrls.filter((_, i) => i !== index));
  };

  return (
    <Box
      sx={{
        padding: '10px',
        border: '2px dashed #ccc',
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? 'dropzone-active' : ''}`}
        style={{
          padding: '20px',
          cursor: 'pointer',
          backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
          transition: 'background-color 0.3s ease',
        }}
      >
        <input {...getInputProps()} />
        <Typography sx={{ color: '#757575', fontFamily: 'Poppins', fontSize: '16px' }}>
          {isDragActive
            ? 'Solte as imagens aqui...'
            : 'Arraste e solte ou clique para selecionar imagens'}
        </Typography>
      </div>

      <Grid container spacing={2} sx={{ marginTop: '16px' }}>
        {/* Renderiza imagens salvas apenas no modo de edição */}
        {isEditMode && carImageUrls.map((url, index) => (
          <Grid item xs={4} sm={3} key={url}>
            <Box
              sx={{
                position: 'relative',
                '&:hover .delete-button': {
                  opacity: 1,
                },
              }}
            >
              <img
                src={url}
                alt="Saved"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                  objectFit: 'cover',
                }}
              />
              <IconButton
                className="delete-button"
                onClick={() => removeSavedImageUrl(index)}
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  backgroundColor: '#fff',
                  boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#f44336',
                    color: '#fff',
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        ))}

        {/* Renderiza novas imagens carregadas */}
        {carImages.map((image, index) => (
          <Grid item xs={4} sm={3} key={image.name}>
            <Box
              sx={{
                position: 'relative',
                '&:hover .delete-button': {
                  opacity: 1,
                },
              }}
            >
              <img
                src={image.preview}
                alt="Preview"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                  objectFit: 'cover',
                }}
              />
              <IconButton
                className="delete-button"
                onClick={() => removeLocalImage(index)}
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  backgroundColor: '#fff',
                  boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#f44336',
                    color: '#fff',
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ImagePicker;